<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-profile
          :module_name="page.name"
          :record="record"
          @change="save()"
          :user_type="record.type"
          :no_notification="true"
          :profile="true">
          <template v-slot:module_fields>
            <v-text-field :label="$store.getters.translate('email')" v-model="record.email"/>
          </template>
        </base-profile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../components/commonComponents/BaseBreadcrumb";
import BaseProfile from "../components/commonComponents/BaseProfile";

export default {
  components: {
    BaseBreadcrumb,
    BaseProfile,
  },
  name: "Profile",
  data() {
    return {
      page: {
        title: this.$store.getters.translate("profile"),
        name: "profile",
      },
      errors: [],
      valid: false,
      loading: false,
      email: "",
      password: "",
      record: {
        person: {
          emailaddresses: [],
        },
        type: 'employee',
      },
      language: "",
      first_load: true,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      let person = this.record.person;
      this.$http
        .get(this.$store.getters.appUrl + "v2/profile")
        .then((response) => {
          this.loading = false;
          this.record = response.data;
          this.language = this.record.language;
          if (!this.record.person) {
            this.record.person = person;
          }
          this.$store.dispatch("setProfileName", this.record.person ? this.record.person.name : this.record.email);
          this.$store.dispatch("setProfileImage", this.record.person && this.record.person.file ? this.record.person.file.full_path : null);
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    cleanCache() {
      this.$toasted.success("cleaned_cache");
      localStorage.clear();
      window.location.reload();
    },
    save() {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.$toasted.success(this.$store.getters.translate("save_success"));
            this.load();
          } else {
            var errorMessage = response.data.message;
            var errors = Object.keys(response.data.message);
            this.$toasted.error([errorMessage[errors[0]]]);
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
  },
  watch: {
    language: {
      handler() {
        if (this.first_load) {
          this.first_load = false;
        } else {
          this.loading = true;
          this.record.language = this.language;
          this.$http
            .post(this.$store.getters.appUrl + "v2/user/setlanguage", this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                this.$store.dispatch("setLanguage", this.language);
                window.location.reload();
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      },
    },
  },
};
</script>
